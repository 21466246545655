// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-blog-js": () => import("./../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-events-js": () => import("./../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-faculty-js": () => import("./../src/pages/faculty.js" /* webpackChunkName: "component---src-pages-faculty-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-narm-certificate-completion-js": () => import("./../src/pages/narm-certificate-completion.js" /* webpackChunkName: "component---src-pages-narm-certificate-completion-js" */),
  "component---src-pages-narm-practitioner-training-manual-module-1-js": () => import("./../src/pages/narm-practitioner-training-manual-module-1.js" /* webpackChunkName: "component---src-pages-narm-practitioner-training-manual-module-1-js" */),
  "component---src-pages-narm-practitioner-training-manual-module-2-js": () => import("./../src/pages/narm-practitioner-training-manual-module-2.js" /* webpackChunkName: "component---src-pages-narm-practitioner-training-manual-module-2-js" */),
  "component---src-pages-narm-practitioner-training-manual-module-3-js": () => import("./../src/pages/narm-practitioner-training-manual-module-3.js" /* webpackChunkName: "component---src-pages-narm-practitioner-training-manual-module-3-js" */),
  "component---src-pages-narm-practitioner-training-manual-module-4-js": () => import("./../src/pages/narm-practitioner-training-manual-module-4.js" /* webpackChunkName: "component---src-pages-narm-practitioner-training-manual-module-4-js" */),
  "component---src-pages-narm-student-resources-js": () => import("./../src/pages/narm-student-resources.js" /* webpackChunkName: "component---src-pages-narm-student-resources-js" */),
  "component---src-pages-obt-2020-js": () => import("./../src/pages/obt2020.js" /* webpackChunkName: "component---src-pages-obt-2020-js" */),
  "component---src-pages-online-learning-inner-circle-js": () => import("./../src/pages/online-learning/inner-circle.js" /* webpackChunkName: "component---src-pages-online-learning-inner-circle-js" */),
  "component---src-pages-online-learning-videos-js": () => import("./../src/pages/online-learning/videos.js" /* webpackChunkName: "component---src-pages-online-learning-videos-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-resources-js": () => import("./../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-schedule-js": () => import("./../src/pages/schedule.js" /* webpackChunkName: "component---src-pages-schedule-js" */),
  "component---src-pages-staff-js": () => import("./../src/pages/staff.js" /* webpackChunkName: "component---src-pages-staff-js" */),
  "component---src-pages-thank-you-js": () => import("./../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-training-assistants-js": () => import("./../src/pages/training-assistants.js" /* webpackChunkName: "component---src-pages-training-assistants-js" */),
  "component---src-pages-trainings-ceus-js": () => import("./../src/pages/trainings/ceus.js" /* webpackChunkName: "component---src-pages-trainings-ceus-js" */),
  "component---src-pages-trainings-curriculum-js": () => import("./../src/pages/trainings/curriculum.js" /* webpackChunkName: "component---src-pages-trainings-curriculum-js" */),
  "component---src-pages-trainings-js": () => import("./../src/pages/trainings.js" /* webpackChunkName: "component---src-pages-trainings-js" */),
  "component---src-pages-transformingtrauma-js": () => import("./../src/pages/transformingtrauma.js" /* webpackChunkName: "component---src-pages-transformingtrauma-js" */),
  "component---src-pages-what-is-narm-js": () => import("./../src/pages/what-is-narm.js" /* webpackChunkName: "component---src-pages-what-is-narm-js" */),
  "component---src-templates-blog-template-js": () => import("./../src/templates/blog-template.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-templates-events-template-js": () => import("./../src/templates/events-template.js" /* webpackChunkName: "component---src-templates-events-template-js" */),
  "component---src-templates-page-template-js": () => import("./../src/templates/page-template.js" /* webpackChunkName: "component---src-templates-page-template-js" */),
  "component---src-templates-trainings-js": () => import("./../src/templates/trainings.js" /* webpackChunkName: "component---src-templates-trainings-js" */),
  "component---src-templates-transformingtrauma-js": () => import("./../src/templates/transformingtrauma.js" /* webpackChunkName: "component---src-templates-transformingtrauma-js" */)
}

